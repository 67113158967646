import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Converter from "../../Assets/Projects/Converter.png";
import Database from "../../Assets/Projects/Database.png";
import ElTorito from "../../Assets/Projects/ElTorito.png";
import PetSitters from "../../Assets/Projects/PetSitters.png";
import PersonalWebsite from "../../Assets/Projects/PersonalWebsite.png";
import PokemonGo from "../../Assets/Projects/PokemonGo.png"
import Potatoes from "../../Assets/Projects/Potatoes.png";
import IbanezAutos from "../../Assets/Projects/IbanezAuto.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've done in the past and kept randomly 
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={PersonalWebsite}
              isBlog={false}
              title="Personal Website"
              description="Pretty much what the name says. An attempt to use React.js with some html/css sections to add some flare to my portfolio.
              Will update with new content in the future...."
              ghLink="https://github.com/Michael-Ibanez"
              demoLink="https://Michaelibanez.org" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Database}
              isBlog={false}
              title="Database Project"
              description="Gene Expression Management System - GEMS. GEMS is a tool to keep track of a study of 
              gene expression at the messenger RNA (mRNA) level in different yeast strains. Created using Python."
              ghLink="https://github.com/Michael-ibanez/DatabaseFinalProject"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={IbanezAutos}
              isBlog={false}
              title="Ibanez Autos"
              description="Website made for a business : Ibanez Automotive. 
              This website will allow a user to browse the vehicles for sale as well as other services offered by the company.
              Created using React.js with Bootstrap."
              ghLink="https://github.com/Michael-ibanez/IbanezAutos/tree/gh-pages"
              demoLink="https://ibanezautos.com/"               
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Potatoes}
              isBlog={false}
              title="Potatoes"
              description="Website made for the 2017-2018 Baylor ECS Hackathon
              for making a website that fits the domain name : WhataPotato.com. 
              Created using HTML/CSS/JS and a game creator."
              ghLink="https://github.com/Michael-ibanez"           
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={PetSitters}
              isBlog={false}
              title="Pet Sitters Website"
              description="Created for a final project to mimic a Pet Sitting service. The Project incorporates several 
              design patterns as well as a good understanding of Java and NoSQL Databases. It was created using HTML/CSS/JS 
              in the frontend and Java as the backend and used Heroku as the hosting server. {Code was lost unfortunately..}"
              demoLink="https://temperature-group5.herokuapp.com/" 
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ElTorito}
              isBlog={false}
              title="El Torito Restaurant"
              description="Website created for El Torito Restaurant. Created using VERY basic HTML/CSS/JS. 
              I made this for fun. Everyone gets bored every once in a while."
              ghLink="https://github.com/Michael-ibanez/ElTorito"
              demoLink="https://ElToritoWacoSportsBar.com" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={PokemonGo}
              isBlog={false}
              title="Pokemon Recreation"
              description="Partial recreation based on the Pokemon Fire Red game. 
              Created using C++ for the the logic and the SDL2/SDL2_Mixer for UI/UX. "
              ghLink="https://github.com/Michael-ibanez/PokemonGo--Project"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Converter}
              isBlog={false}
              title="Currency Converter"
              description="Fun project to test API calls and using JavaFX to build an application. Created using Java. "
              ghLink="https://github.com/Michael-ibanez/JavaProject"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
